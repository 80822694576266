import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useRole, offset, safePolygon, FloatingFocusManager } from '@floating-ui/react'
import { externalMenuitemKeydown, useAriaSpecMenu } from '/features/pageOnly/menu/useAriaSpecMenu'
import { useMenuStructureContext } from './MenuStructureContext'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTranslate } from '/machinery/I18n'

import { useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { Hamburger } from '/features/pageOnly/menu/buildingBlocks/Hamburger'
import { Logo } from '/features/pageOnly/menu/buildingBlocks/Logo'
import { ExpandHorizontal } from '/features/buildingBlocks/Expand'
import { SubmenuDesktop } from '/features/pageOnly/menu/buildingBlocks/Submenu'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { LanguageSwitch } from '/features/pageOnly/menu/buildingBlocks/LanguageSwitch'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/client-safe/sanity'
import { routeMap } from '/routeMap'

import styles from './MenuDesktop.css'

export function MenuDesktop({
  items,
  logoHref,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  pageButtons = [],
  fixedButtons = []
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <nav className={styles.component}>
      <div className={cx(styles.navBarContainer, menuIsExpanded && styles.menuIsExpanded)}>
        <NavBar
          layoutClassName={styles.navBarLayout}
          {...{
            items,
            logoHref,
            pageButtons,
            fixedButtons,
            showSkillsMatchButton,
            skillsMatchUserSelectionCount
          }}
        />
      </div>
    </nav>
  )
}

function NavBar({
  items,
  logoHref,
  pageButtons,
  fixedButtons,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  layoutClassName = undefined
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentNavBar, layoutClassName)}>
      <Navigation layoutClassName={styles.navigationLayout} {...{ items, logoHref }} />
      <ActionMenu layoutClassName={styles.actionMenuLayout} {...{ pageButtons, fixedButtons, showSkillsMatchButton, skillsMatchUserSelectionCount }} />
      {menuIsExpanded && <Background layoutClassName={styles.backgroundLayout} />}
    </div>
  )
}

function Background({ layoutClassName = undefined }) {
  const { submenuHeight, activeSubmenu: { active } } = useNavigationContext()
  const height = active ? submenuHeight : 0

  return (
    <span
      style={{ '--background-height': `${height}px` }}
      className={cx(styles.componentBackground, layoutClassName)}
    />
  )
}

function Navigation({ items, logoHref, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentNavigation, layoutClassName)}>
      <div className={styles.logoContainer}>
        <Logo href={logoHref} layoutClassName={styles.logoLayout} />
      </div>

      <Menu layoutClassName={styles.menuLayout} {...{ items }} />
    </div>
  )
}

function Menu({ items, layoutClassName = undefined }) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div role='menubar' className={cx(styles.componentMenu, menuIsExpanded && styles.isExpanded, layoutClassName)}>
      <ul role='menu' aria-orientation='horizontal' className={styles.menu}>
        {items.map((item, i) => (
          <MenuItem key={i} {...{ item }} />
        ))}
      </ul>
    </div>
  )
}

function MenuItem({ item }) {
  switch (item?._type) {
    case 'referenceWithLabel': return <LinkItem {...{ item }} />
    case 'submenu': return <SubmenuItem {...{ item }} />
    default: return null
  }
}

function LinkItem({ item }) {
  const { structure, setMenuItemRef } = useMenuStructureContext()
  const { menuIsExpanded } = useNavigationContext()

  const { href, label, isActive } = item

  return (
    <li className={styles.componentLinkItem} role='none'>
      <a
        ref={x => setMenuItemRef(item, x)}
        role='menuitem'
        data-x='link-in-menu'
        tabIndex={menuIsExpanded ? 0 : -1}
        className={cx(styles.linkItem, isActive && styles.isActive)}
        onKeyDown={e => externalMenuitemKeydown(e, { structure })}
        {...{ href }}
      >
        <span className={styles.label}>{label}</span>
      </a>
    </li>
  )
}
function SubmenuItem({ item }) {
  const { __ } = useTranslate()

  const { activeSubmenu } = useNavigationContext()
  const { context, getReferenceProps, getItemProps, getFloatingProps } = useFloatingProps({
    id: item._key
  })

  const { _key: id, label, submenuItems } = item
  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.active

  const href = item.ref ? determineDocumentPathSync({ document: item.ref, routeMap }) : undefined
  const Base = item.ref ? 'a' : 'button'

  return (
    <li role='none' className={styles.componentSubmenuItem}>
      <Base
        className={cx(styles.submenuItem, thisSubmenuIsActive && styles.isActive)}
        {...(item.ref ? { href } : {})}
        {...getReferenceProps({ item, index: item.index })}
      >
        <span className={styles.label}>{label}</span>
      </Base>

      {Boolean(thisSubmenuIsActive) && (
        <FloatingFocusManager
          returnFocus
          modal={false}
          restoreFocus={false}
          visuallyHiddenDismiss={__`close-submenu`}
          {...{ context }}
        >
          <div className={styles.dropdown} {...getFloatingProps()}>
            <SubmenuDesktop
              items={submenuItems}
              isActive={thisSubmenuIsActive}
              layoutClassName={styles.submenuDesktopLayout}
              {...{ getItemProps }}
            />
          </div>
        </FloatingFocusManager>
      )}
    </li>
  )
}

function ActionMenu({
  pageButtons,
  fixedButtons,
  skillsMatchUserSelectionCount,
  showSkillsMatchButton,
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()
  const { translations, menuIsExpanded, setMenuIsExpanded } = useNavigationContext()

  /**
   * Note: This custom size is delibrate, to tailor the menu to fit.
   *       Don't change this value unless you have actively tested
   *       its implications, and checked it visually in the browser.
   */
  const isCustomViewportSize = useMediaQuery('screen and (min-width: 1420px)')

  return (
    <div className={cx(styles.componentActionMenu, layoutClassName)}>
      <ExpandHorizontal justification='end' expanded={!menuIsExpanded}>
        <div className={styles.contentSmall}>
          <Hamburger isTabbable={!menuIsExpanded} onClick={() => setMenuIsExpanded(true)} />
        </div>
      </ExpandHorizontal>

      <ExpandHorizontal expanded={menuIsExpanded}>
        <div className={styles.navigationButtons}>
          <LanguageSwitch
            isTabbable={menuIsExpanded}
            layoutClassName={styles.languageSwitchLayout}
            {...{ translations }}
          />

          {showSkillsMatchButton && (
            <ShowSkillsMatchPortalFlowButton
              buttonType='secondary'
              dataX='link-to-skillsmatch'
              tabIndex={menuIsExpanded ? 0 : -1}
              label={skillsMatchUserSelectionCount > 0 ? __`my-skills` : __`what-are-your-skills`}
            />
          )}

          {isCustomViewportSize && pageButtons?.map((x, i) => (
            <ButtonLinkPrimary
              key={i}
              href={x.href}
              label={x.label}
              dataX={x.dataX}
              tabIndex={menuIsExpanded ? 0 : -1}
              layoutClassName={styles.buttonLayout}
            />
          ))}
        </div>
      </ExpandHorizontal>

      {isCustomViewportSize && (
        <div className={styles.fixedButtons}>
          {fixedButtons?.map(({ href, label, dataX }, i) => (
            <ButtonLinkPrimary
              key={i}
              layoutClassName={styles.buttonLayout}
              label={label.labelDesktop}
              {...{ href, dataX }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

function useFloatingProps({ id }) {
  const { submenuHeight, activeSubmenu, onActiveSubmenuChange, setMenuBackgroundHeight } = useNavigationContext()
  const { structure, setMenuItemRef, setSubmenuItemRef } = useMenuStructureContext()

  const isOpen = activeSubmenu.active && activeSubmenu.id === id

  const { y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: handleActiveSubmenuChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      floatingElementSize({ onChange: handleSizeChange }),
      shift({ padding: 0 }),
      offset(2)
    ]
  })

  const style = {
    position: strategy,
    top: y ?? 0,
    left: 0
  }

  const hover = useHover(context, { handleClose: safePolygon({ requireIntent: false }) })
  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: false
  })

  const ariaSpecMenu = useAriaSpecMenu(context, {
    onSubmenuChange: handleSubmenuChange,
    structure,
    id
  })

  const { getReferenceProps, getItemProps, getFloatingProps } = useInteractions([
    hover, role, dismiss, ariaSpecMenu
  ])

  return {
    refs,
    context,
    getReferenceProps: x => getReferenceProps({
      ...x,
      ref: element => {
        refs.setReference(element)
        setMenuItemRef(x.item, element)
      },
    }),
    getFloatingProps: x => getFloatingProps({
      ...x,
      style,
      ref: refs.setFloating,
    }),
    getItemProps: x => getItemProps({
      ...x,
      ref: element => {
        setSubmenuItemRef(id, x.item, element)
      },
    })
  }

  function handleActiveSubmenuChange(active) {
    onActiveSubmenuChange({ id, active })
  }

  function handleSubmenuChange({ id, active }) {
    onActiveSubmenuChange({ id, active })
  }

  function handleSizeChange(x) {
    if (submenuHeight !== x) setMenuBackgroundHeight(x || 0)
  }
}

function floatingElementSize({ onChange }) {
  return {
    name: 'floatingElementSize',
    fn: ({ rects }) => {
      onChange(rects.floating.height)
      return {}
    }
  }
}
